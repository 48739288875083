.xc-nav-footer
{
    padding: 10px;
    background: linear-gradient(to bottom, #00802b 0%, #000000 100%) !important;
    transition: 1.0s;
}

.xc-contact-social-links
{
    background: linear-gradient(to bottom, #00802b 0%, #000000 100%) !important;
    margin-top: -10px;
    transition: 1.0s;
}

.xc-nav-footer-copyright
{
    color: gray;
    background: linear-gradient(to bottom, #00802b 50%, #000000 100%) !important;
    cursor: grab;
}

.pt-4 {
    padding-top: 0 !important;
}

.p-3 {
    padding: 0 !important;
}

.m-1 {
    margin: -10px !important;
}

.mb-4 {
    margin-bottom: 0 !important;
}

.xc-footer-social-link
{
    color: white;
    transition: 1.0s;
}

.xc-footer-social-link:hover
{
    color: #00ff1e;
    transition: 1.0s;
}