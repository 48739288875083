body 
{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a
{
  text-decoration: none;
  color: black;
}

.p-2 
{
  padding: 0 !important;
}

.xc-btn
{
  background: linear-gradient(to bottom, #000000 40%, #00802b 100%) !important;
}

.xc-btn:hover
{
  box-shadow: 0 5px 5px 0 green, 0 3px 10px 0 green;
}

.card
{
  border: 0;
}

.xc-card-body
{
  transition: 1.0s;
  background-color: white;
}

.xc-app-container {
  padding: 60px 15px 35px 15px;
  overflow: auto;
  transition: 1.0s;
}

.xc-route-component-content {
  margin-bottom: 50x;
}

::-webkit-scrollbar {
  display: none;
}

.xc-accordion-item
{
  transition: 1.0s;
}

.xc-accordion-button
{
  background: linear-gradient(to bottom, #000000 40%, #00802b 100%) !important;
  color: white !important;
  transition: 1.0s;
}

.xc-accordion-button:hover
{
  box-shadow: 0 10px 10px 0 green, 0 6px 20px 0 green;
}

.xc-home-tech-section
{
  transition: 1.0s;
  animation: updates 3.0s linear infinite;
}

.xc-home-tech-section:hover
{
  transition: 1.0s;
  box-shadow: 0 10px 10px 0 green, 0 6px 20px 0 green;
}

@keyframes updates { 
  0% { 
      opacity: 0; 
  } 

  50% { 
      opacity: 5.0; 
  } 

  100% { 
      opacity: 0; 
  } 
}

.card-footer
{
  background-color: white;
}

.card-header
{
  background: linear-gradient(to bottom, #000000 40%, #00802b 100%) !important;
  color: white;
}

.xc-contact-card-body
{
  color: white !important;
}