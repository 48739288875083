.xc-nav-header
{
    background: linear-gradient(to bottom, #000000 40%, #00802b 100%) !important;
    transition: 1.0s;
}

.xc-nav-header:hover
{
    box-shadow: 0 10px 10px 0 green, 0 6px 20px 0 green;
}

.xc-nav-canvas-header
{
    background: linear-gradient(to bottom, #000000 40%, #00802b 100%) !important;
    transition: 1.0s;
}

.xc-nav-canvas-header:hover
{
    box-shadow: 0 10px 10px 0 green, 0 6px 20px 0 green;
}

.xc-header-canvas
{
    background-color: rgb(0, 57, 9) !important;
    transition: 1.0s;
}

.xc-nav-canvas-header-link
{
    text-decoration: none;
    color: gray;
    font-size: 11px;
    transition: 1.0s;
}

.xc-nav-canvas-header-link:hover
{
    color: #00ff1e;
}

.xc-header-link
{
    color: white;
}

.xc-header-link:hover
{
    color: #00ff1e;
}

.xc-header-menu
{
    background-color: rgb(0, 57, 9) !important;
    color: white;
}

.xc-header-menu-toggle
{
    color: white;
}

.xc-header-menu-toggle:hover
{
    color: #00ff1e;
}

.xc-header-menu-item:hover
{
    color: #00ff1e;
    background-color: rgb(0, 57, 9) !important;
}

.xc-brand-header
{
    padding: 10px;
    font-weight: bold;
    transition: 1.0s;
}

.xc-brand-header:hover
{
    color: #00ff1e;
}

.xc-header-reward-icon
{
    animation: reward 6s infinite linear;
}

@keyframes reward {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.xc-header-reward-value
{
    margin: 0 15px 0 3px;
}